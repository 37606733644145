// Advice Teaser
//
// Markup: advice__teaser.twig
//
// Styleguide: authoring.advice__teaser
@import "component/authoring/toast/toast"

.advice__teaser-header
  margin: 7px 29px
  color: #999
  font-size: 10px
  font-family: "Theinhardt", sans-serif
  line-height: 15px
  letter-spacing: .83px
  text-transform: uppercase
  padding-top: 2em

.advice--teaser
  position: relative
  margin: 10px 20px
  font-size: 14px

  > .advice__title
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 2px

  &:hover .advice__teaser
    display: unset

.advice__teaser
  padding: 15px
  background: #f8f8f8
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.3)
  line-height: 18px
  letter-spacing: 0.6px
  position: absolute
  top: 50%
  left: 167px
  transform: translateY(-50%)
  display: none
  z-index: 27

  &.advice__teaser--inked
    @extend .toast
    @extend .toast--type_ink

  > .advice__teaser__title
    display: block
    font-weight: bold

  > .advice__teaser__text > a
    font-weight: bold

  .advice__teaser__pointer
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: -5px
    color: #333
    font-size: 25px
    text-shadow: 0px 2px 5px rgba(0,0,0,.3)
