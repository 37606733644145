@import 'variables/colors'

.banner
    display: flex
    z-index: 1000
    align-items: center
    justify-content: space-between
    background: $salmon-brighter
    box-sizing: border-box
    padding: 6px 15px
    border-bottom: 1px solid $white
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px 0
    top: -5px
    left: 0
    right: 0
    position: absolute
    width: 500px
    margin: 0
    transform: translateY(-100%)
    animation: slide-bottom 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) .5s both
    transition: background 0.2s ease-in

    &:hover
        background: $mercury
        transition: background 0.3s ease-in

    &.banner--error
        background: $salmon-brighter
        border-bottom: 1px solid $red
        &:hover
            background: $salmon-brighter
    &.banner--success
        background: $green-brighter
        border-bottom: 1px solid $green
        &:hover
            background: $green-brighter
    &.banner--warning
        background: $corp-brighter
        border-bottom: 1px solid $corp
        &:hover
            background: $corp-brighter

    &.banner--editor
        margin-left: 25px
    &.banner--settings
        margin-left: 100px

    span
        display: flex
        align-items: center
        font-size: 1.75em

    @keyframes slide-bottom
        0%
            transform: translateY(-100%)
        2%
            transform: translateY(7px)
        98%
            transform: translateY(7px)
        100%
            transform: translateY(-100%)
