// Banderole
//
// markup: alert-space.twig
//
// Styleguide: teaser.alert-space

@import 'variables/metrics'
@import 'variables/colors'
@import 'variables/fonts'

.alert-space
  display: inline-block
  padding: 9px 1em 0 0

.alert-space__button
  background-color: $ink
  color: white
  line-height: 26px
  box-sizing: border-box
  padding: .5em 1.5em
  font-size: 14px
  font-family: $sans
  text-decoration: none
  font-weight: 500
  text-transform: uppercase
  display: inline-block

.alert-space__stage
  color: white
  background: $red
  display: inline-block
  line-height: 26px
  box-sizing: border-box
  padding: .5em 1.5em
  font-size: 14px
  font-family: $sans
  text-decoration: none
  font-weight: 500
  position: relative
  letter-spacing: 1.1px
  text-align: center
  width: 100%

@media (min-width: 1200px)
    .alert-space__stage
        width: auto
        
        &::after
            content: ""
            width: 0
            height: 0
            border-style: solid
            border-width: 9px 0 9px 10px
            border-color: transparent transparent transparent $red
            position: absolute
            right: -6px
            top: 50%
            transform: translateY(-50%)
