.settings
    box-sizing: border-box
    overflow: auto
    padding: 20px 40px

    h1
        margin-bottom: 20px

    fieldset
        border: none
        margin: 0 0 60px
        padding: 0

    legend
        font-weight: 800
        font-size: 26px

    p
        margin-bottom: 45px

    .FormRow
        border-bottom: 2px solid #eff3f5
        padding: 15px 15px 15px 0
        display: flex
        justify-content: space-between
        align-items: center

        label
            font-family: "Theinhardt", sans-serif
            font-size: 18px

        &--button
            padding: 0
            justify-content: flex-end

        &--clean
            border: none
            margin-bottom: 5px

        &--intended
            margin-top: -5px
            padding-top: 0px

            label
                padding-left: 25px

    .NumberInput
        border: 1px solid #dedede
        text-align: center
        -moz-appearance: textfield

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
            -webkit-appearance: none
            margin: 0

    .upgrade-overlay
        box-sizing: border-box
        background: #eff3f5
        position: relative
        left: 50%
        transform: translate(-50%, -50%)
        top: 50%
        width: 360px
        padding: 20px 30px 40px
        text-align: center

        &__wrapper
            background: rgba(255, 255, 255, 0.6)
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            z-index: 67

    &--full
        padding: 90px 100px

        h1
            margin-bottom: 70px

        .NumberInput
            width: 35px
            padding: 10px 14px 9px
            margin-right: -10px

    &--compact
        h1
            font-size: 24px

        legend
            font-size: 15px

        fieldset
            padding-bottom: 13px
            margin-bottom: 20px
            border-bottom: 1px solid #dadee0

        .FormRow
            border: none
            padding: 3px 0 6px

            label
                font-size: 13px

        .NumberInput
            width: 25px
            padding: 5px 8px 5px 9px
            font-size: 13px
