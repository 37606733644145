// Switch
//
// Markup: switch.twig
//
// Styleguide: misc.switch

.switch
  position: relative
  display: inline-block
  width: 46px
  height: 23px

.switch__input
  opacity: 0
  width: 0
  height: 0

.switch__slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #eeeeee
  border-radius: 23px
  transition: .4s

.switch__slider:before
  position: absolute
  content: ""
  height: 23px
  width: 23px
  left: 0
  bottom: 0
  background-color: #dddddd
  transition: .4s
  border-radius: 23px

.switch__input:checked + .switch__slider
  background-color: #ffedaa

.switch__input:checked + .switch__slider:before
  transform: translateX(23px)
  background-color: #ffd008

.settings-group__item.settings-group__item--small
  padding-left: 38px
  padding-right: 80px
  display: flex
  align-items: center
  height: 90px

.settings-group__item-label.settings-group__item-label--small
  color: #333
  font-size: 12px
  letter-spacing: 0.4px
  display: inline-block
  line-height: 20px
  padding-left: 0
